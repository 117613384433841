import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { format24hTime, parseDateString } from '../tools/utils';

const DaySlot = styled.span`
  margin: 0;
  font-weight: 600;
  font-size: 1rem;

  @media screen and (min-width: 1000px) {
    display: block;
    position: sticky;
    z-index: 8;
    font-size: 0.75rem;
    text-align: center;
    padding: 10px 5px 5px;
    background-color: rgba(255, 255, 255, 0.9);
    border-left: 1px solid lightgray;
  }
`;

const TimeSlot = styled.h3`
  display: none;

  @media screen and (min-width: 1000px) {
    display: block;
    border-top: 1px solid lightgray;

    grid-column: times;
    margin: 0;
    font-weight: 600;
    font-size: 0.75rem;
  }
`;

const Session = props => {
  const Wrapper = styled(Link)`
    position: relative;
    padding: 0.5rem;
    border-radius: 15px;

    &:hover {
      cursor: pointer;
    }

    &:hover .session-location {
      text-decoration: underline;
    }

    &:active {
      cursor: pointer;
    }

    @media screen and (min-width: 900px) {
      margin: 0;
    }
  `;

  const { title, startTime, endTime, location, startDate = null } = props;

  return (
    <>
      <Wrapper
        to={'/' + props.path}
        className={props.className}
        css={props.css}
      >
        <div>
          <span className="session-title">{title}</span>
          <span className="session-time">
            {format24hTime(startTime)} - {format24hTime(endTime)}
          </span>
          <span className="session-location">at {location}</span>
        </div>
        {parseDateString(startDate).isFutureDate() && (
          <>
            <div
              className="font-body"
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                font-size: 1rem;
                text-align: center;
                font-weight: bold;
                letter-spacing: 0.03em;
                padding: 0.25rem;
                color: white;
                z-index: 5;
                border-radius: 15px;
                background-color: rgba(0, 0, 0, 0.55);

                transition: opacity 0.3s ease;

                &:hover {
                  opacity: 0;
                }
              `}
            >
              <span
                css={css`
                  transform: rotate(-10deg);
                `}
              >
                Starts {parseDateString(startDate).formatDate()}!
              </span>
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export { Session as default, DaySlot, TimeSlot };
