import React from 'react';

const ArticleTitle = ({ title, id, className }) => (
  <h2 id={id} className={`${className}`}>
    {title}
  </h2>
);

const Article = ({ title, id, children }) => (
  <article>
    <ArticleTitle title={title} id={id} />
    {children}
  </article>
);

export default Article;
