import { css, Global } from "@emotion/react";
import { Link } from "gatsby";
import React from "react";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import Article from "../components/article";
import SEO from "../components/seo";
import Sidebar from "../components/sidebar";
import TimeGrid from "../components/timetable_grid";
import TimeGridKids from "../components/timetable_kids";

const Lessons = ({ location }) => (
  <>
    <Global
      styles={css`
        section + section,
        article + article {
          margin-top: 1rem;
        }

        .slot {
          color: #fff;
          transition: transform 0.3s ease;
        }
        .session-title {
          display: block;
          margin: 0;
          font-size: 1rem;
        }
        .session-time,
        .session-location,
        .session-details {
          font-size: 0.75rem;
          display: block;
        }

        @media only screen and (min-width: 900px) {
          .session-title {
            font-size: 0.75rem;
          }
          .session-time {
            display: none;
          }
        }
      `}
    />
    <SEO
      title="Dance Lessons"
      description="Expert tuition in a friendly atmosphere. We offer lessons for all levels of experience, as well as private lessons for those special occasions. Learn to Jive, Cha Cha Cha, Waltz, Foxtrot and more!"
      keywords={[
        `ballroom`,
        `dancing`,
        `learn`,
        `kids`,
        `private`,
        `latin`,
        `waltz`,
        `cha cha cha`,
        `foxtrot`,
        `lessons`,
        `classes`,
      ]}
      location={location}
    />
    <ScrollUpButton style={{ zIndex: "99" }} />
    <Sidebar>
      <ul className="font-body text-sm">
        <li>
          <a href="#info">About our classes</a>
        </li>
        <li>
          <a href="#beginners">Beginners</a>
        </li>
        <li>
          <a href="#improvers">Improvers</a>
        </li>
        <li>
          <a href="#intermediate">Intermediate</a>
        </li>
        <li>
          <a href="#advanced">Advanced</a>
        </li>
        <li>
          <a href="#kids">Children's Ballroom</a>
        </li>
        <li>
          <a href="#schedule">Class Schedule</a>
        </li>
      </ul>
    </Sidebar>
    <section className="flex flex-col">
      <section className="text-justify mx-auto w-full sm:w-4/5">
        <Article title="About our classes" id="info">
          <p>
            For adults we have weekly, 1 hour classes for{" "}
            <span className="text-highlight font-semibold">&pound;8</span>. For
            children, we have a 4 week course for{" "}
            <span className="text-highlight font-semibold">&pound;28</span>.
            Payment by cash or BACS only please, prior the start of each lesson
            or course. Please see the{" "}
            <a href="#schedule" className="hover:underline text-highlight">
              class schedule
            </a>{" "}
            below for times and locations.
          </p>
          <p>
            While we do recommend regular attendance to achieve the best
            results, don't worry if you miss some classes, you'll soon catch up!
          </p>
          <p>We recommend that you wear comfortable clothing and shoes.</p>
          <p>
            If you are not sure which class you want to join, Adults or
            Children, please{" "}
            <Link to="/contact" className="hover:underline text-highlight">
              contact
            </Link>{" "}
            Paula by email, text or telephone to discusss which class is right
            for you.
          </p>
        </Article>
        <Article title="Beginners Ballroom & Latin" id="beginners">
          <p>
            These classes are designed for people without any Ballroom or Latin
            Dance experience. Over a period of weeks, you will be taught to
            comfortably get round the dance floor. Learning Dances: Waltz,
            Quickstep, Rhythm Foxtrot, Jive and Cha Cha Cha.
          </p>
        </Article>
        <Article title="Improvers Ballroom & Latin" id="improvers">
          <p>
            We have a variety of Improvers classes at our venues. If you are
            interested please{" "}
            <Link to="/contact" className="hover:underline text-highlight">
              contact
            </Link>{" "}
            Paula to discuss how much dancing you have learnt and which of our
            classes would be most suitable.
          </p>
        </Article>
        <Article title="Intermediate Ballroom & Latin" id="intermediate">
          <p>
            These classes are designed for people who have practiced for a long
            time and are capable dancers but are interested in progressing
            further.
          </p>
        </Article>
        <Article title="Advanced Ballroom & Latin" id="advanced">
          <p>
            Similar to Intermediate classes but slightly more complicated
            variations in all the Ballroom and Latin dances.
          </p>
        </Article>
        <Article title="Children's Ballroom, Latin & Disco" id="kids">
          <p>
            The children start in a beginners class where they learn ballroom,
            latin and some disco. We have other classes of different levels. We
            encourage the children to take Medals in the{" "}
            <a
              href="https://www.istd.org/examinations/"
              className="hover:underline text-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              ISTD when they are ready
            </a>
            .
          </p>
        </Article>
      </section>
      <section className="w-full mt-6">
        <h2 id="schedule" className="leading-none">
          Class Schedule{" "}
          <span className="inline-block text-xs italic font-body font-normal text-black">
            (select class for location info)
          </span>
        </h2>
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <TimeGrid />
        </div>
        <div
          css={css`
            margin-top: 2rem;
          `}
        >
          <TimeGridKids />
        </div>
      </section>
    </section>
  </>
);

export default Lessons;
