import styled from '@emotion/styled';

const StyledAside = styled.aside`
  display: block;
  width: 100%;
  text-align: center;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  background-color: white;
  z-index: 10;

  a:active,
  a:hover {
    text-decoration: underline;
    color: var(--highlight-color);
  }

  ul,
  li {
    display: inline-block;
    list-style: none;
  }

  li {
    margin-left: 1rem;
  }

  li::after {
    content: ',';
  }

  li:last-of-type::after {
    content: '.';
  }

  + * {
    margin-top: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    display: block;
    padding: 0.5rem 1rem;

    li {
      margin-left: 1rem;
    }

    + * {
      margin-top: 1.5rem;
    }
  }
`;

const Sidebar = ({ children }) => {
  return (
    <StyledAside className="font-medium">
      <p className="font-display font-semibold text-highlight">On this page:</p>
      <nav>{children}</nav>
    </StyledAside>
  );
};

export default Sidebar;
