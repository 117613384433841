function isNewDay(arr, index) {
  return (
    index === 0 ||
    arr[index].day.split("-")[0] !== arr[index - 1].day.split("-")[0]
  );
}

function format24hTime(t) {
  if (typeof t !== "number") {
    t = Number(t);
    if (Number.isNaN(t)) return null;
  }
  let hours = Math.floor(t / 100);
  var suffix = hours >= 12 ? "pm" : "am";
  hours = ((hours + 11) % 12) + 1;
  let minutes = t % 100;
  minutes = String(minutes).padStart(2, "0");

  return `${hours}:${minutes}${suffix}`;
}

const orderedDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

function filterLessons(schedule, place) {
  let filtered = [];
  for (let slot of schedule) {
    let { day, startTime, endTime } = slot;
    day = day.split('-')[0];
    for (let lesson of slot.lessons) {
      if (lesson.place.toLowerCase() === place) {
        filtered.push({
          day,
          startTime,
          endTime,
          title: lesson.title,
          startDate: lesson.startDate,
          endDate: lesson.endDate,
          notes: lesson.notes,
          timeStr: `${format24hTime(startTime)} - ${format24hTime(endTime)}`,
        });
      }
    }
  }
  return filtered.sort((a, b) => {
    if (orderedDays.indexOf(a.day) > orderedDays.indexOf(b.day)) return 1;
    if (orderedDays.indexOf(a.day) < orderedDays.indexOf(b.day)) return -1;

    if (a.startTime > b.startTime) return 1;
    if (a.startTime < b.startTime) return -1;

    return 0;
  });
}

function parseDateString(dateStr) {
  // expected date formats: 1999-12-30T03:59, 1999-12-30
  const DateTimeExp = /(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(?:T(0[0-9]|1\d|2[0-3]):(0[0-9]|[1-5]\d))?/i;

  let dateTime = null;
  const matches = DateTimeExp.exec(dateStr);

  if (matches) {
    const [, year, month, day, hours = 0, minutes = 0] = matches;
    dateTime = new Date(year, month - 1, day, hours, minutes);
  }

  const isFutureDate = () => {
    if (!dateTime) return false;

    return dateTime.getTime() > Date.now();
  };

  const isPastDate = () => {
    if (!dateTime) return false;

    return dateTime.getTime() < Date.now();
  };

  const formatDate = () => {
    if (!dateTime) return null;

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let formatted = `${dateTime.getDate()} ${
      months[dateTime.getMonth()]
    } ${dateTime.getFullYear()}`;
    return formatted;
  };

  const getShortYear = () => {
    return String(dateTime.getFullYear()).substring(2);
  };

  const getMonthName = () => {
    if (!dateTime) return null;

    return dateTime.toLocaleDateString('en-GB', {
      month: 'long',
    });
  };

  const getFormattedDay = () => {
    if (!dateTime) return null;

    let d = dateTime.getDate();
    let suffix = 'th';

    if (d > 3 && d < 21) suffix = 'th';
    else {
      switch (d % 10) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
      }
    }

    return String(d).concat(suffix);
  };

  const getFormattedTime = () => {
    if (!dateTime) return null;

    let time = `${dateTime.getHours()}${dateTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    // console.log("time: ", time);

    return format24hTime(time);
  };

  return {
    isFutureDate,
    isPastDate,
    formatDate,
    getMonthName,
    getFormattedDay,
    getFormattedTime,
    getShortYear,
    dateTime,
  };
}

export { parseDateString, filterLessons, format24hTime, isNewDay };
