import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Session, { DaySlot, TimeSlot } from "../components/timetableSession";
import schedule from "../data/class_schedule";
import { isNewDay, parseDateString } from "../tools/utils";

const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1000px) {
    display: grid;
    grid-template-rows:
      [days] auto
      [time-1640] auto
      [time-1730] auto
      [time-1900] auto
      [time-1930] auto
      [time-2000] auto
      [time-2030] auto
      [time-2100] auto
      [time-2130] auto
      [time-2200] auto;

    grid-template-columns:
      [times] 48px
      [monday-start] auto
      [monday-end monday-overlap-start] auto
      [monday-overlap-end tuesday-start] auto
      [tuesday-end wednesday-start] auto
      [wednesday-end wednesday-overlap-start] auto
      [wednesday-overlap-end thursday-start] auto
      [thursday-end thursday-overlap-start] auto
      [thursday-overlap-end thursday-overlap2-start] auto
      [thursday-overlap2-end];
      [friday-start] auto;
      [friday-end] auto
  }
`;

const table = (props) => {
  let data = schedule.map((slot) => {
    let lessons = slot.lessons.map((lesson) => {
      let slug = lesson.title.split(" ").splice(0, 2).join("-").toLowerCase();
      let loc = lesson.place.split(" ").join("-").toLowerCase();
      return { ...lesson, slug, loc };
    });
    return { ...slot, lessons };
  });

  return (
    <>
      <Global
        styles={css`
          .hillside-hall {
            background-color: #d60060;
          }
          .orchard-hall {
            background-color: #0064d3;
          }
          .paulton-rovers-club {
            background-color: #8a00eb;
          }
          .kilmersdon {
            background-color: #d64000;
          }
          .chippenham {
            background-color: #008000;
          }
        `}
      />
      <Wrapper>
        <TimeSlot
          css={css`
            grid-row: time-1640;
          `}
        >
          4:40pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1730;
          `}
        >
          5:30pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1900;
          `}
        >
          7:00pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1930;
          `}
        >
          7:30pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-2000;
          `}
        >
          8:00pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-2030;
          `}
        >
          8:30pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-2100;
          `}
        >
          9:00pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-2130;
          `}
        >
          9:30pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-2200;
          `}
        >
          10:00pm
        </TimeSlot>

        {data.map((slot, i) => {
          return (
            <React.Fragment key={i}>
              {isNewDay(data, i) && (
                <DaySlot
                  aria-hidden="true"
                  css={css`
                    grid-column: ${slot.day.toLowerCase() === "wednesday"
                      ? slot.day.toLowerCase() + "/ span 2"
                      : slot.day.toLowerCase() === "thursday"
                      ? slot.day.toLowerCase() + "/ span 2"
                      : slot.day.toLowerCase()};
                    grid-row: days;
                  `}
                >
                  {slot.day + "s"}
                </DaySlot>
              )}
              <div
                css={css`
                  grid-column: ${slot.day.toLowerCase()};
                  grid-row: ${"time-" + slot.startTime} /
                    ${"time-" + slot.endTime};
                  display: flex;
                  justify-content: space-between;
                  padding: 0.2rem;

                  a + a {
                    margin-left: 0.2rem;
                  }
                `}
              >
                {slot.lessons.map((lesson, i) => {
                  if (!parseDateString(lesson.endDate).isPastDate())
                    return (
                      <Session
                        key={i}
                        className={`${lesson.loc} slot`}
                        css={css`
                          flex: 0 1 100%;
                        `}
                        title={lesson.title}
                        startTime={slot.startTime}
                        endTime={slot.endTime}
                        location={lesson.place}
                        startDate={lesson.startDate}
                        path={lesson.loc}
                      />
                    );
                  return null;
                })}
              </div>
            </React.Fragment>
          );
        })}
      </Wrapper>
    </>
  );
};

export default table;
