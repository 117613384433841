import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Session, { DaySlot, TimeSlot } from "../components/timetableSession";
import schedule from "../data/kids_schedule";
import { isNewDay, parseDateString } from "../tools/utils";

const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1000px) {
    display: grid;
    width: 320px;
    grid-template-rows:
      [days] auto
      [time-930] auto
      [time-1020] auto
      [time-1030] auto
      [time-1120] auto
      [time-1130] auto
      [time-1145] auto
      [time-1215] auto
      [time-1220] auto
      [time-1230] auto
      [time-1245] auto
      [time-1315] auto
      [time-1320] auto
      [time-1400] auto;

    grid-template-columns:
      [times] 48px
      [saturday-start] auto
      [saturday-end saturday-overlap-start] auto
      [saturday-overlap-end saturday-overlap-2-start];
      [saturday-overlap-2-end];
  }
`;

const table = (props) => {
  let data = schedule.map((slot) => {
    let lessons = slot.lessons.map((lesson) => {
      let slug = lesson.title.split(" ").splice(0, 2).join("-").toLowerCase();
      let loc = lesson.place.split(" ").join("-").toLowerCase();
      return { ...lesson, slug, loc };
    });
    return { ...slot, lessons };
  });

  return (
    <>
      <Wrapper>
        <TimeSlot
          css={css`
            grid-row: time-930;
          `}
        >
          9:30am
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1020;
          `}
        >
          10:20am
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1030;
          `}
        >
          10:30am
        </TimeSlot>

        <TimeSlot
          css={css`
            grid-row: time-1120;
          `}
        >
          11:20am
        </TimeSlot>

        <TimeSlot
          css={css`
            grid-row: time-1130;
          `}
        >
          11:30am
        </TimeSlot>

        <TimeSlot
          css={css`
            grid-row: time-1145;
          `}
        >
          11:45am
        </TimeSlot>

        <TimeSlot
          css={css`
            grid-row: time-1220;
          `}
        >
          12:20pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1230;
          `}
        >
          12:30pm
        </TimeSlot>

        <TimeSlot
          css={css`
            grid-row: time-1245;
          `}
        >
          12:45pm
        </TimeSlot>
        <TimeSlot
          css={css`
            grid-row: time-1315;
          `}
        >
          1:15pm
        </TimeSlot>

        {data.map((slot, i) => {
          return (
            <React.Fragment key={i}>
              {isNewDay(data, i) && (
                <DaySlot
                  aria-hidden="true"
                  css={css`
                    grid-column: ${slot.day.toLowerCase() + "/ span 2"};
                    grid-row: days;
                  `}
                >
                  {slot.day + "s"}
                </DaySlot>
              )}
              <div
                css={css`
                  grid-column: ${slot.day.toLowerCase()};
                  grid-row: ${"time-" + slot.startTime} /
                    ${"time-" + slot.endTime};
                  display: flex;
                  justify-content: space-between;
                  padding: 0.2rem;
                  perspective: 100px;

                  a + a {
                    margin-left: 0.2rem;
                  }
                `}
              >
                {slot.lessons.map((lesson, i) => {
                  if (
                    (lesson.type === "kids") &
                    !parseDateString(lesson.endDate).isPastDate()
                  )
                    return (
                      <Session
                        key={i}
                        className={`${lesson.loc} slot`}
                        css={css`
                          flex: 0 1 100%;
                        `}
                        title={lesson.title}
                        startTime={slot.startTime}
                        endTime={slot.endTime}
                        location={lesson.place}
                        startDate={lesson.startDate}
                        path={lesson.loc}
                      />
                    );
                  return null;
                })}
              </div>
            </React.Fragment>
          );
        })}
      </Wrapper>
    </>
  );
};

export default table;
